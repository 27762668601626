.graphic-background-bg-illustration-homepage {
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  .graphic-background-bg-illustration-homepage .graphic-background-left {
    margin-right: auto;
    min-width: max-content; }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bg-illustration-homepage .graphic-background-left {
        height: intrinsic; } }
    @media (max-width: 1676px) {
      .graphic-background-bg-illustration-homepage .graphic-background-left {
        padding-right: 80px; } }
  .graphic-background-bg-illustration-homepage .graphic-background-right {
    margin-left: auto;
    min-width: max-content; }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bg-illustration-homepage .graphic-background-right {
        height: intrinsic; } }
    @media (max-width: 1676px) {
      .graphic-background-bg-illustration-homepage .graphic-background-right {
        padding-left: 80px; } }
  .graphic-background-bg-illustration-homepage[data-animation-css]:not(.is-animated) [data-top-left-animation],
  .graphic-background-bg-illustration-homepage[data-animation-css]:not(.is-animated) [data-top-right-animation],
  .graphic-background-bg-illustration-homepage[data-animation-css]:not(.is-animated) [data-animation-path],
  .graphic-background-bg-illustration-homepage[data-animation-css]:not(.is-animated) [data-animation-cube] {
    animation: unset !important; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-cube],
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-coin],
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-dot],
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-vertical-loop-sm],
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-vertical-loop],
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-up-down],
  .graphic-background-bg-illustration-homepage[data-animation-css].is-paused [data-animation-down-up] {
    animation-play-state: paused; }
  .graphic-background-bg-illustration-homepage[data-animation-css] [data-top-left-animation],
  .graphic-background-bg-illustration-homepage[data-animation-css] [data-top-right-animation],
  .graphic-background-bg-illustration-homepage[data-animation-css] [data-animation-cube] {
    opacity: 0; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-top-left-animation] {
    animation-name: showElementTopLeft-shared-hosting;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
    animation-fill-mode: forwards; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-top-right-animation] {
    animation-name: showElementTopRight-shared-hosting;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
    animation-fill-mode: forwards; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-delay-1] {
    animation-delay: 0s; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-delay-2] {
    animation-delay: 0.12s; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-delay-3] {
    animation-delay: 0.24s; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube] {
    will-change: transform, opacity;
    animation-name: cubeLoop-shared-hosting;
    animation-duration: 2.20s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube]:nth-child(1) {
      animation-delay: 0.32s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube]:nth-child(2) {
      animation-delay: 0.96s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube]:nth-child(3) {
      animation-delay: 1.24s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-1] {
      --cubeStart: 60px;
      --cubeGap: -50px; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-2] {
      --cubeStart: 40px;
      --cubeGap: -70px; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-3] {
      --cubeStart: 20px;
      --cubeGap: -70px; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-sm-1] {
      --cubeStart: 60px;
      --cubeGap: -25px; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-sm-2] {
      --cubeStart: 50px;
      --cubeGap: -35px; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-sm-3] {
      --cubeStart: 50px;
      --cubeGap: -35px; }
  .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path] {
    will-change: transform, opacity;
    animation-timing-function: linear;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-1] {
      animation-name: pathAnimation1-shared-hosting;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-2] {
      animation-name: pathAnimation2-shared-hosting;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-3] {
      animation-name: pathAnimation3-shared-hosting;
      animation-duration: 1.5s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-4] {
      animation-name: pathAnimation4-shared-hosting;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-5] {
      animation-name: pathAnimation5-shared-hosting;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-6] {
      animation-name: pathAnimation6-shared-hosting;
      animation-duration: 0.6s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-7] {
      animation-name: pathAnimation7-shared-hosting;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-8] {
      animation-name: pathAnimation8-shared-hosting;
      animation-duration: 1.3s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-9] {
      animation-name: pathAnimation9-shared-hosting;
      animation-duration: 1s;
      animation-delay: 1.08s; }

@keyframes pathAnimation1-shared-hosting {
  from {
    stroke-dashoffset: 78; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation2-shared-hosting {
  from {
    stroke-dashoffset: 116; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation3-shared-hosting {
  from {
    stroke-dashoffset: 718; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation4-shared-hosting {
  from {
    stroke-dashoffset: 316; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation5-shared-hosting {
  from {
    stroke-dashoffset: 90; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation6-shared-hosting {
  from {
    stroke-dashoffset: 257; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation7-shared-hosting {
  from {
    stroke-dashoffset: 642; }
  to {
    stroke-dashoffset: 0; } }

@keyframes showElementTopLeft-shared-hosting {
  from {
    transform: translate3d(52px, 30px, 0px);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0px);
    opacity: 1; } }

@keyframes showElementTopRight-shared-hosting {
  from {
    transform: translate3d(-52px, 30px, 0px);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0px);
    opacity: 1; } }

@keyframes cubeLoop-shared-hosting {
  0% {
    transform: translate3d(0px, var(--cubeStart), 0px);
    opacity: 0; }
  10%, 60% {
    opacity: 1; }
  to {
    transform: translate3d(0px, var(--cubeGap), 0px);
    opacity: 0; } }

[dir="rtl"] .graphic-background-bg-illustration-shared-hosting .graphic-background-left {
  margin-left: auto;
  margin-right: 0;
  transform: scaleX(-1); }

[dir="rtl"] .graphic-background-bg-illustration-shared-hosting .graphic-background-right {
  margin-left: 0;
  margin-right: auto;
  transform: scaleX(-1); }
